import Vue from 'vue';
import VueRouter from 'vue-router';

import Layout from './layouts/Layout.vue';
import BookInfo from './pages/BookInfo.vue';
import BooksList from './pages/BooksList.vue';
import BooksFavorites from './pages/BooksFavorites.vue';
import BooksReaded from './pages/BooksReaded.vue';
import BooksCategories from './pages/BooksCategories.vue';
import PageNotFound from './pages/PageNotFound.vue';

export default function(params)
{
	params["router"] = new VueRouter({
		mode: 'history',
		base: '/',
		routes:
		[
			{
				path: '/',
				redirect: '/books',
			},
			{
				path: '/',
				component: Layout,
				children: [
					{
						path: '/books',
						component: BooksCategories,
					},
					{
						path: '/books/favorites',
						component: BooksFavorites,
					},
					{
						path: '/books/readed',
						component: BooksReaded,
					},
					{
						path: '/books/:category_id',
						component: BooksList,
					},
					{
						path: '/book/:id',
						component: BookInfo,
					},
					{
						path: '/book',
						redirect: '/books',
					},
					{
						path: '*',
						component: PageNotFound,
					},
				],
				props: { default: true, namespace: ["page", "Home"] },
			},
		],
		//linkActiveClass: 'active',
		//linkExactActiveClass: 'active',
	});
	
	params["store"].registerModule(["page", "Layout"], Layout.buildStore());
	params["store"].registerModule(["page", "BookInfo"], BookInfo.buildStore());
	params["store"].registerModule(["page", "BooksList"], BooksList.buildStore());
	params["store"].registerModule(["page", "BooksFavorites"], BooksFavorites.buildStore());
	params["store"].registerModule(["page", "BooksReaded"], BooksReaded.buildStore());
	params["store"].registerModule(["page", "BooksCategories"], BooksCategories.buildStore());
	
	return params;
}
