<template>
	<div>
		<main role="main" class="container">
			<loading-spinner
				:overlay="overlay"
			></loading-spinner>
			<div v-if="!overlay">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb">
						<li class="breadcrumb-item active">
							<router-link :to="{path: '/books'}">Список категорий ({{booksCategoriesCount}})</router-link>
						</li>
					</ol>
				</nav>
				<div class="list-group">
					<router-link custom :to="{path: '/books/0'}" v-slot="{href, route, navigate, isActive, isExactActive}">
						<a :class="['list-group-item', 'list-group-item-action', {'active': isExactActive}]" :href="href" @click="navigate">
							<div class="mt-2 mb-2">
								<i class="fa fa-reorder me-2"></i>
								<span>Без категории</span>
							</div>
						</a>
					</router-link>
					<router-link custom :to="{path: '/books/'+cat.id}" v-slot="{href, route, navigate, isActive, isExactActive}" v-for="cat in booksCategories">
						<a :class="['list-group-item', 'list-group-item-action', {'active': isExactActive}]" :href="href" @click="navigate">
							<div class="mt-2 mb-2">
								<i class="fa fa-reorder me-2"></i>
								<span class="badge rounded-pill bg-primary pull-right">{{cat.books_count}}</span>
								<span v-html="cat.name"></span>
							</div>
						</a>
					</router-link>
				</div>
			</div>
		</main>
	</div>
</template>

<style lang="css">

</style>

<script>
import LoadingSpinner from '../components/LoadingSpinner.vue';

export default {
	name: 'BooksCategories',
	props: ['namespace'],
	data: () => ({
		booksCategories: [],
		booksCategoriesCount: 0,
		overlay: true,
	}),
	methods: {
		loadPostData(){
			this.overlay = true;
			fetch('/api/books/categories')
				.then(stream => stream.json())
				.then(data => {
					this.booksCategories = data.categories;
					this.booksCategoriesCount = data.count;
					
					setTimeout(() => {
						this.overlay = false;
					}, 500);
				})
				.catch(error => console.error(error));
		},
		/*setDarkMode(){
			this.$store.dispatch('darkModeSet', !this.darkMode)
			.then(() => {
				if(this.darkMode){
					document.body.classList.remove('bg-light');
					document.body.classList.add('bg-dark');
				} else {
					document.body.classList.remove('bg-dark');
					document.body.classList.add('bg-light');
				}
			})
			.catch(err => {
				
			});
		},*/
	},
	beforeMount(){
		window.scrollTo(0, 0);
		this.loadPostData();
	},
	 beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		this.loadPostData();
	},
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {};
		}
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		layout(){
			return this.$store.state.page.Layout
		},
		/*darkMode(){
			return this.$store.state.darkMode;
		},*/
	},
	components: {
		LoadingSpinner,
	},
}
</script>