<template>
	<div>
		<main role="main" class="container">
			<loading-spinner
				:overlay="overlay"
			></loading-spinner>
			<div v-if="!overlay">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb">
						<li class="breadcrumb-item">
							<router-link :to="{path: '/books'}">Список категорий</router-link>
						</li>
						<li class="breadcrumb-item" v-if="book">
							<router-link :to="{path: '/books/'+book.category_id}">Список книг категории</router-link>
						</li>
						<li class="breadcrumb-item active" aria-current="page">Книга</li>
					</ol>
				</nav>
				<div v-if="book">
					<div class="card">
						<div class="card-body">
							<div class="mt-3 mb-4 text-center">
								<h1 v-if="book.category_name" v-html="'['+book.category_name+']'"></h1>
								<h1 v-tml="book.title"></h1>
							</div>
							<div class="text-justify" v-html="book.content"></div>
							<div class="mt-5 text-center">
								<hr/>
								источник: <a href="http://wapsekas.com">http://wapsekas.com</a>
							</div>
						</div>
						<div class="card-footer">
							<div class="row">
								<div class="col-6">
									<div class="d-grid gap-2 d-block">
										<button type="button" class="btn btn-lg btn-info" @click="setReaded()" v-if="!book.readed"><i class="fa fa-check"></i> Прочитано</button>
										<button type="button" class="btn btn-lg btn-default" @click="setUnReaded()" v-else><i class="fa fa-minus"></i> Непрочитано</button>
									</div>
								</div>
								<div class="col-6">
									<div class="d-grid gap-2 d-block">
										<button type="button" class="btn btn-lg btn-warning" @click="setFavorited()" v-if="!book.favorited"><i class="fa fa-star"></i> Избранное</button>
										<button type="button" class="btn btn-lg btn-default" @click="setUnFavorited()" v-else><i class="fa fa-star-o"></i> Избранное</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="list-group-item text-center">
						<i class="fa fa-book text-muted mt-4" style="font-size: 10em;"></i>
						<h4 class="mt-4 mb-4">Книга не найдена</h4>
					</div>
					<div class="mt-3 text-center">
						<router-link :to="{path: '/books'}" class="btn btn-lg btn-info">К списку книг</router-link>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'BookInfo',
	props: ['namespace'],
	data: () => ({
		book: [],
		overlay: true,
	}),
	methods: {
		loadPostData(id){
			this.overlay = true;
			fetch('/api/book/'+id)
				.then(stream => stream.json())
				.then(data => {
					this.book = data;
					
					this.book.readed = this.book.readed == '1';
					this.book.favorited = this.book.favorited == '1';
					
					setTimeout(() => {
						this.overlay = false;
					}, 500);
				})
				.catch(error => console.error(error));
		},
		setFavorited(){
			fetch('/api/book/'+this.book.id+'/favorited/add')
				.then(stream => stream.json())
				.then(data => {
					this.book.favorited = true;
				})
				.catch(error => console.error(error));
		},
		setUnFavorited(){
			fetch('/api/book/'+this.book.id+'/favorited/delete')
				.then(stream => stream.json())
				.then(data => {
					this.book.favorited = false;
				})
				.catch(error => console.error(error));
		},
		setReaded(){
			fetch('/api/book/'+this.book.id+'/readed/add')
				.then(stream => stream.json())
				.then(data => {
					this.book.readed = true;
				})
				.catch(error => console.error(error));
		},
		setUnReaded(){
			fetch('/api/book/'+this.book.id+'/readed/delete')
				.then(stream => stream.json())
				.then(data => {
					this.book.readed = false;
				})
				.catch(error => console.error(error));
		},
	},
	beforeMount(){
		this.loadPostData(this.$route.params.id);
		window.scrollTo(0, 0);
	},
	 beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		this.loadPostData(to.params.id);
	},
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {};
		}
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		layout(){
			return this.$store.state.page.Layout
		},
	},
}
</script>