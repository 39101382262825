<template>
	<div>
		<main role="main" class="container">
			<loading-spinner
				:overlay="overlay"
			></loading-spinner>
			<div v-if="!overlay">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb">
						<li class="breadcrumb-item">
							<router-link :to="{path: '/books'}">Список категорий</router-link>
						</li>
						<li class="breadcrumb-item active" aria-current="page">Список книг категории ({{booksCount}})</li>
					</ol>
				</nav>
				<div class="list-group">
					<div v-if="books.length > 0">
						<router-link custom :to="{path: '/book/'+book.id}" v-slot="{href, route, navigate, isActive, isExactActive}" v-for="book in books">
							<a class="list-group-item list-group-item-action" :href="href" @click="navigate">
								<div class="mt-2 mb-2">
									<i class="fa fa-book me-2"></i>
									<div class="pull-right" v-html="'['+book.category_name+']'"></div>
									<div class="pull-right">
										<i v-if="book.favorited == '1'" class="fa fa-star me-2"></i>
									</div>
									<span v-html="book.title"></span>
								</div>
							</a>
						</router-link>
					</div>
					<div v-else>
						<div class="list-group-item text-center">
							<h4 class="mt-3 mb-3">Список прочитанных книг пуст</h4>
						</div>
						<div class="mt-3 text-center">
							<router-link :to="{path: '/books'}" class="btn btn-lg btn-info">К списку категорий</router-link>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<style lang="css">

</style>

<script>
import LoadingSpinner from '../components/LoadingSpinner.vue';

export default {
	name: 'BooksReaded',
	props: ['namespace'],
	data: () => ({
		books: [],
		booksCount: 0,
		overlay: true,
	}),
	methods: {
		loadPostData(){
			this.overlay = true;
			fetch('/api/books/readed')
				.then(stream => stream.json())
				.then(data => {
					this.books = data.books;
					this.booksCount = data.count;
					
					setTimeout(() => {
						this.overlay = false;
					}, 500);
				})
				.catch(error => console.error(error));
		},
		
	},
	beforeMount(){
		window.scrollTo(0, 0);
		this.loadPostData();
	},
	 beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		this.loadPostData();
	},
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {};
		}
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		layout(){
			return this.$store.state.page.Layout
		},
	},
	components: {
		LoadingSpinner,
	},
}
</script>