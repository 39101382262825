<template>
	<div class="mb-4">
		<!--
		<back-to-top visibleoffset="300" bottom="40px" right="40px">
			<i class="fa fa-angle-up"></i>
		</back-to-top>
		-->
		<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
			<div class="container-fluid">
				<router-link :to="{path: '/'}" class="navbar-brand">eBookReader</router-link>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbarCollapse">
					<ul class="navbar-nav mr-auto">
						<router-link custom :to="{path: '/books/favorites'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<li :class="['nav-item', {'active': isExactActive}]">
								<a :href="href" @click="navigate" class="nav-link">Избранное</a>
							</li>
						</router-link>
						<router-link custom :to="{path: '/books/readed'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<li :class="['nav-item', {'active': isExactActive}]">
								<a :href="href" @click="navigate" class="nav-link">Прочитанное</a>
							</li>
						</router-link>
						<router-link custom :to="{path: '/books'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<li :class="['nav-item', {'active': isExactActive}]">
								<a :href="href" @click="navigate" class="nav-link">Категории</a>
							</li>
						</router-link>
					</ul>
				</div>
			</div>
		</nav>
		<router-view/>
	</div>
</template>

<style lang="css">
.vue-back-to-top {
    font-size: 2.2em;
    position: fixed;
    bottom: 23px;
    right: 23px;
    z-index: 40;
    width: 56px;
    height: 56px;
    color: #fff;
    background-color: #233445;
    border: 0;
    border-radius: 50%;
    text-align: center;
    outline: none;
	cursor: pointer;
}

.breadcrumb {
	padding: .75rem 1rem;
    margin-bottom: 1rem;
    background-color: #e9ecef;
}
.breadcrumb .breadcrumb-item a {
	text-decoration: none;
}
</style>

<script>
import BackToTop from 'vue-backtotop';

export default {
	name: 'Layout',
	props: ['namespace'],
	data: () => ({
		
	}),
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {
				
			};
		}
		
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		
		return res;
	},
	components: {
		BackToTop,
	},
	computed: {
		model(){
			return this.getModel();
		},
		layout(){
			return this.$store.state.page.Layout
		},
		/*darkMode(){
			return this.$store.state.darkMode;
		},*/
	},
	methods: {
		loadData(){
			var that = this;
			var store = this.$store;
			
			/*fetch('/api/materials-list').then(stream => stream.json()).then(data => {
				that.menu = data.materials;
			}).catch(error => {
				console.error(error);
			});*/
		},
		/*setDarkMode(){
			this.$store.dispatch('darkModeSet', !this.darkMode)
			.then(() => {
				if(this.darkMode){
					document.body.classList.remove('bg-light');
					document.body.classList.add('bg-dark');
				} else {
					document.body.classList.remove('bg-dark');
					document.body.classList.add('bg-light');
				}
			})
			.catch(err => {
				
			});
		},*/
	},
	created(){
		this.loadData();
	},
	mounted(){
		/*if(this.darkMode){
			document.body.classList.add('bg-dark');
		} else {
			document.body.classList.add('bg-light');
		}*/
	},
}
</script>
